

import React, { FormEvent, useState, useEffect } from 'react';

import './index.less';

const Header = (props) => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo') || '{}'));

    const logOut = () => {
        localStorage.removeItem('userInfo');
        window.location.href = '/#/login';
    }

    return <div className="app-header flex items-center">
        <div className="flex-grow-1">
            {props.children}
        </div>

        <div className="info-wrap">
            <div className="inner flex items-center pointer">
                <div className="avatar" style={{backgroundImage: `url(${userInfo.avatar})`}}></div>
                <div>{userInfo.nickName}</div>
                <div className="btn-logout text-primary" onClick={logOut}>
                    退出
                </div>
            </div>
        </div>
    </div>
};

export default Header;