import {message} from 'antd';

const noneValue = (method, value) => {
    if (value || (value === 0) || (value === false)) {
        return value;
    }

    if ((method === 'GET') || (method === 'DELETE')) {
        return '';
    } else {
        return null;
    }
};

const $fetch = (url, option = {}) => {
    const { method = 'GET', params={}, headers, resType = 'json', ...other } = option;

    const {token} = JSON.parse(localStorage.getItem('userInfo') || '{}')

    // 区分 GET 和 POST

    const msgClear = message.loading('请求中');

    if ((method === 'GET') || (method === 'DELETE')) {
        let queryString = params ? Object.keys(params).map(key => `${key}=${noneValue(method, params[key])}`).join('&') : '';

        if (queryString && url.indexOf('?') === -1) {
            queryString = `?${queryString}`;
        }

        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_BASE_URL || ''}${url}${queryString}`, {
                method,
                credentials: 'include',
                headers: headers || {
                    'Content-Type': 'application/json',
                    access_token: token,
                },
                ...other,
            }).then(res => res[resType]()).then(resp => {

                msgClear();

                if (resp.code === 3001) {
                    message.error('用户未登录或token已过期，请重新登录');
                    window.location.href = '/#/login';
                    localStorage.removeItem('userInfo');
                    reject({message: '用户未登录'});
                    return;
                }

                if (resp.code === 0) {
                    return resolve(resp);
                }

                resp.message && message.error(resp.message);

                reject(resp);

                return resolve(resp);
            }).catch(e => {
                reject(e);
                msgClear();
            });
        });
    }

    Object.keys(params).forEach(key => {
        params[key] = noneValue(method, params[key]);
    });

    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_BASE_URL || ''}${url}`, {
            method,
            body: JSON.stringify(params),
            credentials: 'include',
            headers: headers || {
                'Content-Type': 'application/json',
                access_token: token,
            },
            ...other,
        }).then(res => res[resType]()).then(resp => {
            msgClear();

            if (resp.code === 3001) {
                message.error('用户未登录或token已过期，请重新登录');
                window.location.href = '/#/login';
                localStorage.removeItem('userInfo');
                reject({message: '用户未登录'});
                return;
            }

            if (resp.code === 0) {
                return resolve(resp);
            }

            resp.message && message.error(resp.message);

            reject(resp);

        }).catch(e => {
            console.log(e);
            e.message && message.error(e.message);
            reject(e);
            msgClear();

        });
    });
};

export default $fetch;