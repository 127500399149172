
import React, { useEffect, useState } from 'react';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, UserOutlined, SettingOutlined, CloseOutlined } from '@ant-design/icons';


import {
    Breadcrumb, Button,
    Form, Input,
    message,
    Switch,
    Tabs,
} from "antd";
import {usePageViews} from "../../App";
import {addSpecialCoders, apiGetAutoDistribute, apiSetAutoDistribute, getSpecialCoders, delSpecialCoders} from "../../api";

const { TabPane } = Tabs;

const Settings = () => {
    usePageViews();

    const [isTestMode, setIsTestMode] = useState(false);

    const [coders1, setCoders1] = useState([]);

    const [coders2, setCoders2] = useState([]);

    const [coders3, setCoders3] = useState([]);

    const [id1, setId1] = useState('');

    const [id2, setId2] = useState('');

    const [id3, setId3] = useState('');

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {
        try {
            const [auto, coders1, coders2, coders3] = await Promise.all([apiGetAutoDistribute(), getSpecialCoders({task: 1}), getSpecialCoders({task: 2}), getSpecialCoders({task: 3})]);

            console.log(auto, coders1, coders2);

            setCoders1(coders1.data || []);
            setCoders2(coders2.data || []);
            setCoders3(coders3.data || []);

            setIsTestMode(!!auto.data.state);
        } catch (e) {

        }
    }

    const handleTestModeToggle = async (status) => {
        const res = await apiSetAutoDistribute(status ? 1 : 0);

        message.success(`已${status ? '开启' : '关闭'}公测`);

        setIsTestMode(status);
    };

    const handleDel = ({task, index}) => async () => {
        if (task === 1) {

            await delSpecialCoders({
                task,
                id: coders1[index],
            });

            message.success('已删除！');

            setCoders1(prev => {
                const _arr = [...prev];
                _arr.splice(index, 1);

                return _arr;
            });

        }

        if (task === 2) {

            await delSpecialCoders({
                task,
                id: coders2[index],
            });

            message.success('已删除！');

            setCoders2(prev => {
                const _arr = [...prev];
                _arr.splice(index, 1);

                return _arr;
            });
        }

        if (task === 3) {

            await delSpecialCoders({
                task,
                id: coders3[index],
            });

            message.success('已删除！');

            setCoders3(prev => {
                const _arr = [...prev];
                _arr.splice(index, 1);

                return _arr;
            });
        }
    }

    const handleAddItem = (task) => async () => {

        if (task === 1) {

            if (!id1) {
                message.error('码工ID不能为空！');
            }

            await addSpecialCoders({
                task,
                id: id1,
            });

            message.success('添加成功！');

            if(id1 > 50000){
                setCoders1(prev => [id1].concat(prev));
            }else{
                getSettings();
            }

            setId1('');
        }

        if (task === 2) {
            if (!id2) {
                message.error('码工ID不能为空！');
            }

            await addSpecialCoders({
                task,
                id: id2,
            });

            message.success('添加成功！');

            if(id2 > 50000){
                setCoders2(prev => [id2].concat(prev));
            }else{
                getSettings();
            }

            setId2('');

        }

        if (task === 3) {
            if (!id3) {
                message.error('码工ID不能为空！');
            }

            await addSpecialCoders({
                task,
                id: id3,
            });

            message.success('添加成功！');

            if(id3 > 50000){
                setCoders3(prev => [id3].concat(prev));
            }else{
                getSettings();
            }

            setId3('');

        }
    };

    return <div className="page-settings">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <SettingOutlined />
                    <span>应用配置</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <Form
                style={{marginTop: 20}}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 19, offset: 1 }}
                layout="horizontal"
            >
                <Form.Item label="开启公测">
                    <Switch checked={isTestMode} onChange={handleTestModeToggle} />
                </Form.Item>
            </Form>

            <div className="tabs-wrap">
                <p className="title">配置打码任务码工</p>
                <p className='content-panel-common'>特殊指令[仅限于第一阶段和第二阶段]：-1（采用预定义值），0（清空），1~50000（从在线的码工中获取指定数量的码工填入列表）</p>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab={`第一阶段(${coders1.length})`} key="1">
                        <div className="flex justify-between">
                            <Input placeholder="输入码工ID" value={id1} onChange={(e) => setId1(e.target.value)} />
                            <Button style={{marginLeft: 20, width: 100}} type="primary" onClick={handleAddItem(1)} >增加</Button>
                        </div>
                        <div className="coder-list">
                            {
                                coders1.map((item, index) => <div key={index} className="item">
                                    <div className="blk1">{item}</div>
                                    <div className="icon-del" onClick={handleDel({
                                        task: 1,
                                        index,
                                    })}>
                                        <CloseOutlined />
                                    </div>
                                </div>)
                            }
                        </div>
                    </TabPane>
                    <TabPane tab={`第二阶段登录(${coders2.length})`} key="2">
                        <div className="flex justify-between">
                            <Input placeholder="输入码工ID" value={id2} onChange={(e) => setId2(e.target.value)} />
                            <Button style={{marginLeft: 20, width: 100}} type="primary" onClick={handleAddItem(2)}>增加</Button>
                        </div>
                        <div className="coder-list">
                            {
                                coders2.map((item, index) => <div key={index} className="item">
                                    <div className="blk1">{item}</div>
                                    <div className="icon-del" onClick={handleDel({
                                        task: 2,
                                        index,
                                    })}>
                                        <CloseOutlined />
                                    </div>
                                </div>)
                            }
                        </div>
                    </TabPane>
                    <TabPane tab={`参与内测(${coders3.length})`} key="3">
                        <div className="flex justify-between">
                            <Input placeholder="输入码工ID" value={id3} onChange={(e) => setId3(e.target.value)} />
                            <Button style={{marginLeft: 20, width: 100}} type="primary" onClick={handleAddItem(3)}>增加</Button>
                        </div>
                        <div className="coder-list">
                            {
                                coders3.map((item, index) => <div key={index} className="item">
                                    <div className="blk1">{item}</div>
                                    <div className="icon-del" onClick={handleDel({
                                        task: 3,
                                        index,
                                    })}>
                                        <CloseOutlined />
                                    </div>
                                </div>)
                            }
                        </div>
                    </TabPane>
                </Tabs>
            </div>

        </div>

    </div>;
};

export default Settings;