
import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, DatePicker, } from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, TeamOutlined,  FileImageOutlined, AimOutlined, SubnodeOutlined, ProjectOutlined, RotateRightOutlined, } from '@ant-design/icons';

import locale from 'antd/lib/calendar/locale/zh_CN.js'
import {apiCaptchaList, apiWorkerList} from "../../api";
import {CaptchaImage, CaptchaType} from "../TaskList";

const { Column, ColumnGroup } = Table;

const { RangePicker } = DatePicker;

const rangeConfig = {
    // rules: [{ type: 'array', required: true, message: '请选择时间!' }],
}

const CodeList = () => {

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        getCaptchaList({
            captchaId: '',
            pageIndex: 1,
        });

    }, []);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const getCaptchaList = async (
        {
            captchaId,
            pageIndex = 1
        }
    ) => {
        const res = await apiCaptchaList({
            captchaId,
            pageIndex,
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        console.log(res);

        setTableData(res.data.lists.map((item, index) => ({...item, key: index})));
        // setTableData(res.data.lists.map(item => ({...item, key: item.userId})));
    };

    const onFinish = ({captchaId =''}) => {
        getCaptchaList({
            captchaId: captchaId.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const captchaId = form.getFieldValue('captchaId') || '';

        getCaptchaList({
            captchaId: captchaId.trim(),
            pageIndex: page,
        });
    };

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <ProjectOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <TeamOutlined />
                    <span>验证码管理</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="captchaId"
                                label="验证码ID"
                            >
                                <Input placeholder="输入验证码ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="rangePicker" label="选择时间段" {...rangeConfig}>
                                <RangePicker local={ locale } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        pageSize: 10,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column  width={140} title="验证码ID" dataIndex="captchaID" key="captchaID" />
                    <Column
                        title="验证码类型"
                        dataIndex="captchaType"
                        key="captchaType"
                        width={80}
                        render={
                            (text) => <CaptchaType type={text} />
                        }
                    />
                    <Column
                        title="提示"
                        dataIndex="prompt"
                        // key="data.prompt"
                        render={
                            (text, record) => (record.data.prompt && record.data.prompt.startsWith("data:") ? (<img src={record.data.prompt} height="40" />) : record.data.prompt)
                        }
                    />
                    <Column
                        title="验证码图片"
                        dataIndex="imageUrl"
                        render={
                            (text, captcha) => <CaptchaImage captcha={captcha} />
                        }
                    />
                    <Column
                        title="滑块图片"
                        dataIndex="pieceUrl"
                        render={
                            (text, record) => (record.data.piece && <img src={record.data.piece} height={120} />)
                        }
                    />
                    
                    <Column title="结果"
                            width={80}
                            dataIndex="result"
                            className="text-primary"
                            render={
                                (text, record) => record.answer ? record.answer.data : ''
                            }
                    />
                    <Column width={166} title="上传时间" dataIndex="createTime" key="createTime" />
                    {/*<Column title="打码结果" dataIndex="codeResult" key="codeResult" />*/}
                    <Column
                        title="操作"
                        key="action"
                        width={100}
                        render={(text, record) => (
                            <Space size="middle">
                                <a className="text-primary">验证</a>
                                <a className="text-primary">通过</a>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>
    </div>;
};

export default CodeList;