
import React, { FormEvent, useState, useEffect } from 'react';

import {Breadcrumb, Menu} from 'antd';
import { HomeOutlined, UserOutlined, HistoryOutlined, DeploymentUnitOutlined, TeamOutlined, ProjectOutlined, SettingOutlined } from '@ant-design/icons';
import {Link, useLocation} from "react-router-dom";

const { SubMenu } = Menu;

const  rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const SiderMenu = () => {

    const [activeMenu, setActiveMenu] = useState('');

    const location = useLocation();

    // useEffect(() => {
    //
    // }, [location]);

    return <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
    >
        <Menu.Item key="/">
            <Link to="/">
                <HomeOutlined />
                首页
            </Link>
        </Menu.Item>
        <Menu.Item key="/worker-list">
            <Link to="/worker-list">
                <TeamOutlined />
                码工管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/code-list">
            <Link to="/code-list">
                <ProjectOutlined />
                验证码管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/task-list">
            <Link to="/task-list">
                <DeploymentUnitOutlined />
                打码任务管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/record-list">
            <Link to="/record-list">
                <HistoryOutlined />
                打码记录管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/settings">
            <Link to="/settings">
                <SettingOutlined />
                应用配置
            </Link>
        </Menu.Item>
        <Menu.Item key="/personal">
            <Link to="/personal">
                <UserOutlined />
                个人中心
            </Link>
        </Menu.Item>
    </Menu>;
};


export default SiderMenu;