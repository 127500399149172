import React, { useEffect } from 'react';

import { Layout } from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';
import {usePageViews} from "../../App";

const Home = (props) => {

    usePageViews();

    useEffect(() => {

    }, []);

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                    <span style={{marginLeft: 8}}>首页</span>
                </Breadcrumb.Item>
                {/*<Breadcrumb.Item href="">*/}
                {/*    <UserOutlined />*/}
                {/*    <span>Application List</span>*/}
                {/*</Breadcrumb.Item>*/}
                {/*<Breadcrumb.Item>Application</Breadcrumb.Item>*/}
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            首页，包含验证码或码工的一些统计指标。
        </div>
    </div>;
};

export default withRouter(Home);
