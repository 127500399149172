import $fetch from "./fetch";

export const apiWorkerList = ({nickName, pageIndex = 1, pageSize = 10, userId} = {
    nickName: '', pageIndex: 1, pageSize: 10, userId: ''
}) => {
    return $fetch('/users/page', {
        params: {
            nickName,
            pageIndex,
            pageSize,
            userId,
        }
    });
}

export const apiCaptchaList = ({captchaId, pageIndex = 1, pageSize = 10} = {
    captchaId: '', pageIndex: 1, pageSize: 10
}) => {
    return $fetch('/captchas/page', {
        params: {
            captchaId,
            pageIndex,
            pageSize,
        }
    });
}

export const apiCodeList = ({codeId, userId, pageIndex = 1, pageSize = 10} = {
    codeId: '', userId: '', pageIndex: 1, pageSize: 10
}) => {
    return $fetch('/captcha-results/page', {
        params: {
            crid: codeId,
            pageIndex,
            pageSize,
            uid: userId,
        }
    });
}


export const apiGetAutoDistribute = () => {
    return $fetch('/system/auto_distribute');
}

export const apiSetAutoDistribute = (status) => {
    return $fetch(`/system/auto_distribute/${status}`, {
        method: 'PUT',
    });
}

export const apiUsersOnline = () => {
    return $fetch('/users/online');
};


export const apiCaptchaTask = (
    {
        start,
        end,
        codeId,
        pageIndex = 1,
        pageSize = 20,
    } = {
        pageIndex: 1,
        pageSize: 20,
    }) => {
    return $fetch('/captcha-tasks/page', {
        params: {
            start,
            end,
            codeId,
            pageIndex,
            pageSize,
        }
    });
};


export const captchaResChoose = (crId) => {
    return $fetch('/captcha-results/choose', {
        method: 'POST',
        params: {
            crId,
        }
    });
};

export const addSpecialCoders = (
    {
        task,
        id,
    }
) => {
    return $fetch(`/users/${id}/special/${task}`, {
        method: 'PUT',
    });
};

export const delSpecialCoders = (
    {
        task,
        id,
    }
) => {
    return $fetch(`/users/${id}/special/${task}`, {
        method: 'DELETE',
    });
};

export const getSpecialCoders = (
    {
        task
    }
) => {

    return $fetch(`/users/special_coders/${task}`, {
        method: 'GET',
    });
};