
import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Modal, message} from 'antd';
import {useParams, useRouteMatch} from 'react-router-dom';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, DownOutlined, UpOutlined, TeamOutlined, FileImageOutlined, AimOutlined, SubnodeOutlined, ProjectOutlined, HistoryOutlined, ExclamationCircleOutlined, RotateRightOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiCaptchaList, apiCodeList, captchaResChoose} from "../../api";
import {CaptchaImage, CaptchaType} from "../TaskList";

const { Column, ColumnGroup } = Table;

const RecordList = () => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const match = useRouteMatch({
        path: '/record-list/:workerid',
        strict: true,
        sensitive: true
    });

    useEffect(() => {
        // console.log(match);

        const workerId = (match && match.params && match.params.workerid) || undefined;

        form.setFieldsValue({
            userId: workerId,
        })

        getCodeList({
            userId: workerId,
            pageIndex: 1,
        });

    }, []);

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const getCodeList = async (
        {
            codeId,
            userId,
            pageIndex = 1
        }
    ) => {
        const res = await apiCodeList({
            codeId,
            userId,
            pageIndex,
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        console.log(res);

        setTableData(res.data.lists.map((item, index) => ({...item.captcha, nickName: item.nickName, ...item.result, key: index})));
        // setTableData(res.data.lists.map(item => ({...item, key: item.userId})));
    };

    const getCodeType = (type) => {
        switch (type) {
            case 0:
                return <span className="text-primary"><FileImageOutlined /> 图片</span>;
            case 1:
                return <span className="text-error"><AimOutlined /> 打点</span>;
            case 2:
                return <span className="text-success"><SubnodeOutlined /> 滑块</span>;
            case 3:
                return <span className="text-warning"><RotateRightOutlined /> 旋转</span>;
        }
    };

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const onFinish = ({codeId ='', userId = ''}) => {
        getCodeList({
            codeId: codeId.trim(),
            userId: userId.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const codeId = form.getFieldValue('codeId') || '';
        const userId = form.getFieldValue('userId') || '';

        getCodeList({
            captchaId: codeId.trim(),
            userId: userId.trim(),
            pageIndex: page,
        });
    };

    const handleSetTestCaptcha = (crId) => () => {
        // console.log(crId);
        Modal.confirm({
            title: '确认提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要将此验证码作为测试码么?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await captchaResChoose(crId);

                console.log(res);

                if (res.code == 0) {
                    message.success('测试码设置成功');
                } else {
                    message.error(res.message);
                }

            },
            onCancel() {

            },
          });
    };

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <HistoryOutlined />
                    <span>打码记录管理</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="codeId"
                                label="记录ID"
                            >
                                <Input placeholder="输入记录ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="userId"
                                label="码工ID"
                            >
                                <Input placeholder="输入码工ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            {/*captcha: {captchaID: "eedba1f70b06b463e0e8fddc5f38bc25940", captchaType: 0, data: {,…},…}*/}
            {/*captchaID: "eedba1f70b06b463e0e8fddc5f38bc25940"*/}
            {/*captchaType: 0*/}
            {/*createTime: "2020-08-12 11:14:46"*/}
            {/*data: {,…}*/}
            {/*updateTime: "2020-08-12 11:14:46"*/}
            {/*nickName: "June"*/}
            {/*result: {crId: "1294118437462872064", codeId: "1294118423424536576", userId: "1292805427855233024",…}*/}
            {/*codeId: "1294118423424536576"*/}
            {/*crId: "1294118437462872064"*/}
            {/*createTime: "2020-08-14 03:47:33"*/}
            {/*receiveTime: "2020-08-14 03:47:36"*/}
            {/*result: {data: "9979"}*/}
            {/*state: 1*/}
            {/*userId: "1292805427855233024"*/}

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        pageSize: 10,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column  width={180} title="记录ID" dataIndex="crId" key="crId" />

                    <Column
                        title="类型"
                        dataIndex="captchaType"
                        key="captchaType"
                        width={80}
                        render={
                            (text) => <CaptchaType type={text} />
                        }
                    />

                    <Column
                        title="提示"
                        dataIndex="prompt"
                        // key="data.prompt"
                        width={160}
                        render={
                            (text, record) => (record.data.prompt && record.data.prompt.startsWith("data:") ? (<img src={record.data.prompt} height="40" />) : record.data.prompt)
                        }
                    />
                    <Column
                        title="验证码图片"
                        dataIndex="imageUrl"
                        render={
                            (text, captcha) => <CaptchaImage captcha={captcha} />
                        }
                    />

                    <Column
                        title="滑块"
                        dataIndex="pieceUrl"
                        width={80}
                        render={
                            (text, record) => (record.data.piece && <img src={record.data.piece} height={120} />)
                        }
                    />

                    <Column title="结果"
                            width={220}
                            dataIndex="result"
                            className="text-primary"
                            render={
                                (text, record) => record.result ? 
                                <div>
                                    {record.result.data}
                                    {record.captchaType == 0 ? <Button style={{marginLeft:10}} size="small" onClick={handleSetTestCaptcha(record.crId)}>设为测试码</Button> : ''}
                                    </div> : ''
                            }
                    />

                    <Column title="耗时"
                            width={100}
                            dataIndex="time"
                            render={
                                (text, record) => record.receiveTime ? (new Date(record.receiveTime).getTime() - new Date(record.createTime).getTime()) : ''
                            }
                    />

                    <Column width={100} title="语音识别" dataIndex="voiceCost" key="voiceCost" />
                    <Column width={200} title="分配时间" dataIndex="createTime" key="createTime" />

                    <Column width={200} title="打码时间" dataIndex="receiveTime" key="receiveTime" />

                    <Column width={350} title="归属ID" dataIndex="codeId" key="codeId" render={(codeId, record) =>
                        <div className="fs-12">
                            <div>codeID: {record.codeId}</div>
                            <div>验证码ID: {record.captchaID}</div>
                            <div>码工ID: {record.userId}</div>
                            <div>优先级: {record.priority}</div>
                            <div>语音文件: {record.voiceKey || '-'}</div>
                        </div>
                    } />
                </Table>
            </div>
        </div>
    </div>;
};

export default RecordList;