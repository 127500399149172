
import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu } from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, DownOutlined, UpOutlined, TeamOutlined, CloseCircleOutlined, ProjectOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiUsersOnline, apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const WorkerList = () => {

    usePageViews();

    useEffect(() => {
        getWorkerList({
            nickName: '',
            userId: '',
            pageIndex: 1,
        });

        getUsersOnline();
    }, []);

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [totalOnline, setTotalOnline] = useState(0);
    const [waiting, setWaiting] = useState(0);
    const [afk, setAfk] = useState(0);
    const [busy, setBusy] = useState(0);
    const [afkAndBusy, setAfkAndBusy] = useState(0);
    const [captchaSize, setCaptchaSize] = useState(0);
    const [specialOnline, setSpecialOnline] = useState(0);
    const [phase, setPhase] = useState(0);

    const getUsersOnline = async () => {
        const res = await apiUsersOnline();

        if(res.data != null){
            setTotalOnline(res.data.online);
            setWaiting(res.data.waiting);
            setAfk(res.data.afk);
            setBusy(res.data.busy);
            setAfkAndBusy(res.data.afkAndBusy);
            setCaptchaSize(res.data.captchaSize);
            setSpecialOnline(res.data.specialOnline);
            setPhase(res.data.phase);    
        }
    };

    const getWorkerList = async ({
        nickName,
        userId,
        pageIndex = 1,
    }) => {
        const res = await apiWorkerList({
            nickName,
            userId,
            pageIndex
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        setTableData(res.data.lists.map(item => ({...item, key: item.userId})));
    };

    const onFinish = ({nickName = '', userId =''}) => {
        getWorkerList({
            nickName: nickName.trim(),
            userId: userId.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const nickName = form.getFieldValue('nickName') || '';
        const userId = form.getFieldValue('userId') || '';

        getWorkerList({
            nickName: nickName.trim(),
            userId: userId.trim(),
            pageIndex: page,
        });
    };

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <TeamOutlined />
                    <span>码工管理</span>

                    <span className="text-error" style={{marginLeft: 10}}>{totalOnline}人在线</span>
                    <span className="text-error" style={{marginLeft: 10}}>{captchaSize}个验证码等待</span>
                    （<span className="text-error" style={{marginLeft: 10}}>{specialOnline}人特殊在线【阶段{phase}】</span>
                    <span className="text-error" style={{marginLeft: 10}}>{waiting}人等待</span>
                    <span className="text-error" style={{marginLeft: 10}}>{afk}人暂离</span>
                    <span className="text-error" style={{marginLeft: 10}}>{busy}人正在打码</span>
                    <span className="text-error" style={{marginLeft: 10}}>{afkAndBusy}人有任务的情况下暂离</span>）
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="nickName"
                                label="微信昵称"
                            >
                                <Input placeholder="输入微信昵称" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="userId"
                                label="码工ID"
                            >
                                <Input placeholder="输入码工ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        pageSize: 10,
                        onChange: pageChange,

                    }}
                    dataSource={tableData}
                >
                    <Column title="码工ID" dataIndex="userId" key="userId" />
                    <Column title="微信昵称" dataIndex="nickName" key="nickName" />
                    <Column
                        title="码工头像"
                        dataIndex="avatarUrl"
                        render={
                            (text, record) => (<img src={text} width={80} />)
                        }
                    />
                    <Column title="状态" dataIndex="online" key="online" render={(text, record) => (<div>
                        <p><Tag icon={record.online ? <CheckCircleOutlined /> : <CloseCircleOutlined />} color={record.online ? "#21d255" : "#ff5500"}>online</Tag></p>
                        <p style={{margin: '4px 0'}}><Tag icon={record.ready ? <CheckCircleOutlined /> : <CloseCircleOutlined />} color={record.ready ? "#21d255" : "#ff5500"}>ready</Tag></p>
                        <p><Tag icon={record.idle ? <CheckCircleOutlined /> : <CloseCircleOutlined />} color={record.idle ? "#21d255" : "#ff5500"}>idle</Tag></p>
                    </div>)}>
                    </Column>

                    <Column title="打码数" dataIndex="totalHit" key="totalHit" />

                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <a className="text-primary" target="_blank" href={`/#/record-list/${record.userId}`}>打码记录</a>
                                <a className="text-error">禁用</a>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>
    </div>;
};

export default WorkerList;