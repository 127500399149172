
import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, DatePicker } from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, DeploymentUnitOutlined,  FileImageOutlined, AimOutlined, SubnodeOutlined, TableOutlined, ProjectOutlined, RotateRightOutlined, } from '@ant-design/icons';

import {apiCaptchaList, apiCaptchaTask, apiWorkerList} from "../../api";

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/calendar/locale/zh_CN.js'

const { Column, ColumnGroup } = Table;

const { RangePicker } = DatePicker;

const rangeConfig = {
    // rules: [{ type: 'array', required: true, message: '请选择时间!' }],
}

export const CaptchaType = ({type}) => {
    switch (type) {
        case 0:
            return <span className="text-primary"><FileImageOutlined /> 图片</span>;
        case 1:
            return <span className="text-error"><AimOutlined /> 打点</span>;
        case 2:
            return <span className="text-success"><SubnodeOutlined /> 滑块</span>;
        case 3:
            return <span className="text-warning"><RotateRightOutlined /> 旋转</span>;
        case 4:
            return <span style={{color: '#666'}}><TableOutlined /> 拼图</span>;
        default:
            return <span>未知</span>
    }
};

export const CaptchaImage = ({captcha}) => {

    const type = captcha.captchaType;
    const image = captcha.data.image;

   if (type == 4) {
        const imgs = image.split('_');
        const column = captcha.data.column;

        return <div style={{width: 280}}>
            {
                imgs.map((item, index) => <img style={{margin: 2, width: 280 / column - 4}} key={index} src={item} />)
            }
        </div>
    } else {
        return <img src={image} />
    }
}

const TaskList = () => {

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        getTaskList({
            codeId: '',
            pageIndex: 1,
        });

    }, []);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const getTaskList = async (
        {
            start,
            end,
            codeId,
            pageIndex = 1
        }
    ) => {
        const res = await apiCaptchaTask({
            start,
            end,
            codeId,
            pageIndex,
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        console.log(res);

        setTableData(res.data.lists.map((item, index) => ({...item, key: index})));
        // setTableData(res.data.lists.map(item => ({...item, key: item.userId})));
    };

    const onFinish = ({codeId = '', timeRange}) => {

        console.log(codeId, timeRange);

        const [start, end] = timeRange || [];

        getTaskList({
            start: start ? start.format('YYYY-MM-DD') : '',
            end: end ? end.format('YYYY-MM-DD') : '',
            codeId: codeId.trim(),
            pageIndex: 1,
        });
    };



    const pageChange = (page) => {
        const codeId = form.getFieldValue('codeId') || '';
        const [start, end] = form.getFieldValue('timeRange') || [];

        getTaskList({
            start: start ? start.format('YYYY-MM-DD') : '',
            end: end ? end.format('YYYY-MM-DD') : '',
            codeId: codeId.trim(),
            pageIndex: page,
        });
    };

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <DeploymentUnitOutlined />
                    <span>打码任务管理</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="codeId"
                                label="任务ID"
                            >
                                <Input placeholder="输入任务ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="timeRange"
                                label="选择时间段"
                                {...rangeConfig}
                            >
                                <RangePicker locale={locale} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        pageSize: 20,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >

                    <Column width={120} title="任务ID" dataIndex="captchaTask" key="captchaTask" render={(captchaTask) => captchaTask.codeId} />
                    <Column width={80} title="任务状态" dataIndex="captchaTask" key="captchaTask" render={(captchaTask) => <span className={['', 'text-primary', 'text-warning', 'text-success', 'text-error'][parseInt(captchaTask.status)]}>{[ '初始等待', '已发送给码工', '收到结果等待回复给developer', '已完成', '已失效'][parseInt(captchaTask.status)]}</span>} />
                    <Column width={80} title="任务类型" dataIndex="captchaTask" key="captchaTask" render={(captchaTask) => <span className={['', 'text-primary', 'text-warning', 'text-success', 'text-error'][parseInt(captchaTask.type)]}>{[ '普通', '练习', '测试'][parseInt(captchaTask.type)]}</span>} />
                    <Column title="结果"
                            width={220}
                            dataIndex="captchaTask"
                            key="captchaTask"
                            className="text-primary"
                            render={
                                (captchaTask) => captchaTask.result ? 
                                <div>
                                    {captchaTask.result.data} &nbsp;<Tag color="success">{captchaTask.votes} / {captchaTask.voters}</Tag>
                                    </div> : ''
                            }
                    />

                    <Column
                        width={100}
                        title="验证码类型"
                        dataIndex="captcha"
                        key="captcha"
                        render={
                            (captcha) => <CaptchaType type={captcha.captchaType} />
                        }
                    />         
                    <Column
                        width={120}
                        title="提示"
                        dataIndex="captcha"
                        key="captcha"
                        render={
                            (captcha) => (captcha.data.prompt && captcha.data.prompt.startsWith("data:") ? (<img src={captcha.data.prompt} height="40" />) : captcha.data.prompt)
                        }
                    />
                    <Column
                        width={200}
                        title="验证码图片"
                        dataIndex="captcha"
                        key="captcha"
                        render={
                            (captcha) => <CaptchaImage captcha={captcha} />
                        }
                    />
                    <Column
                        width={120}
                        title="滑块图片"
                        dataIndex="captcha"
                        key="captcha"
                        render={
                            (captcha) => (captcha.data.piece && <img src={captcha.data.piece} height={120} />)
                        }
                    />
                    <Column width={140} title="创建时间" dataIndex="captchaTask" key="captchaTask" render={captchaTask => captchaTask.createTime} />
                    <Column width={200} title="其他信息" dataIndex="captchaTask" key="captchaTask" render={(captchaTask, record) =>
                        <div className="fs-12">
                            <div>验证码ID: {record.captcha.captchaID}</div>
                            <div>验证码创建时间: {record.captcha.createTime}</div>
                            <div>优先级: {captchaTask.priority}</div>
                            <div>任务更新时间: {captchaTask.updateTime}</div>
                            <div>任务完成时间: {captchaTask.completeTime || '--'}</div>
                            <div>任务过期时间: {captchaTask.expireAt || '--'}</div>
                            <div>发码客户端ID: {captchaTask.fromClientId || '--'}</div>
                            <div>标书ID: {captchaTask.bidId || '--'}</div>
                            <div>标书号: {captchaTask.bidNumber || '--'}</div>
                        </div>
                    } />
                    {/*<Column title="打码结果" dataIndex="codeResult" key="codeResult" />*/}
                    {/*<Column*/}
                    {/*    title="操作"*/}
                    {/*    key="action"*/}
                    {/*    render={(text, record) => (*/}
                    {/*        <Space size="middle">*/}
                    {/*            <a className="text-primary">验证</a>*/}
                    {/*            <a className="text-primary">通过</a>*/}
                    {/*        </Space>*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Table>
            </div>
        </div>
    </div>;
};

export default TaskList;