import $fetch from "./fetch";

export const apiLogin = (code) => {
    return $fetch('/admin/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain'
        },
        body: code
    });
};